import React, { useState, useEffect } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import LanguageSelector from './LanguageSelector'
import Form from './Form'
import defaultValue from '../Lib/defaultValue'
import colorFromString from '../Lib/colorFromString'

const generateToken = length => {
  const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split(
    '',
  )
  const b = []
  for (let i = 0; i < length; i++) {
    const j = (Math.random() * (a.length - 1)).toFixed(0)
    b[i] = a[j]
  }
  return b.join('')
}

const ListItem = SortableElement(({ value: { value: item, sorting, models, languages, updateStored, deleteModel, showErrors, modelsAvailable, index, files } }) => (
  <div className="bg-white rounded">
    <div
      className={`${sorting ? 'cursor-move' : ''} relative mt-2 rounded`}
      style={{
        backgroundColor: colorFromString(item.modelName, 0.3),
      }}
    >
      <div
        className={`flex items-center justify-between rounded-t ${
          sorting ? 'px-1 py-1 text-xs' : 'p-4'
        }`}
        style={{
          backgroundColor: colorFromString(item.modelName, 0.3),
        }}
      >
        <span className="wght-semibold">
          {modelsAvailable[item.modelName]}
        </span>
        {!sorting && <span
          className="text-xs cursor-pointer hover:wght-semibold"
          onClick={() => deleteModel(index)}
        >
          Delete
        </span>}
      </div>
      <div
        className={`p-2 ${
          sorting ? 'md:py-1 md:px-1 text-xs h-40 overflow-scroll pointer-events-none' : 'md:py-4 md:px-2'
        }`}
      >
        <Form
          innerForm={true}
          parentIsSorting={sorting}
          key={item.key}
          onChange={(n, v) => updateStored(index, item.modelName, n, v)}
          showErrors={showErrors}
          name={item.modelName}
          data={{ models, stored: item, languages, files }}
        />
      </div>
    </div>
  </div>
  )
)

const List = SortableContainer(({ items, sorting, models, languages, updateStored, deleteModel, showErrors, modelsAvailable, files }) => (
  <div>
    {Object.keys(items).map((itemKey, index) => (
      <ListItem
        key={`${items[itemKey].key}-${index}`}
        index={index}
        value={{ value: items[itemKey], sorting, models, languages, updateStored, deleteModel, showErrors, modelsAvailable, index, files }}
      />
    ))}
  </div>
))

const Models = ({
  showErrors,
  storedData,
  onChange,
  models,
  name,
  translatable,
  options: { label, models: modelsAvailable },
  languages,
  innerForm,
  parentIsSorting,
  files,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [sorting, setSorting] = useState(false)
  const [value, setValue] = useState(
    Object.values(defaultValue(name, storedData, translatable, [])).map(
      m => {
        if (typeof m.key === 'undefined') m.key = generateToken(30)
        return m
      },
    ),
  )

  useEffect(() => {
    onChange(name, value)
  }, [value])

  const deleteModel = index => {
    if (window.confirm('Delete item?')) {
      if (translatable) {
        value[language].splice(index, 1)
        value[language] = [...value[language]]
        setValue({ ...value })
      } else {
        value.splice(index, 1)
        setValue([...value])
      }
    }
  }

  const updateStored = (index, modelName, key, v) => {
    const updatedValue = [...value]
    if (translatable) {
      if (typeof value[language][index] === 'undefined') { updatedValue[language][index] = { modelName } }
      updatedValue[language][index][key] = v
    } else {
      if (typeof updatedValue[index] === 'undefined') { updatedValue[index] = { modelName } }
      updatedValue[index][key] = v
    }
    if (JSON.stringify(updatedValue) !== JSON.stringify(value)) {
      setValue([...updatedValue])
    }
  }

  const addModel = modelKey => {
    let position = (value.length + 1) * 10
    if (
      value.length > 0
      && typeof value[value.length - 1].position !== 'undefined'
      && value[value.length - 1].position.length > 0
    ) {
      position = parseInt(value[value.length - 1].position) + 10
    }
    setValue([
      ...value,
      { modelName: modelKey, key: generateToken(30), position },
    ])
  }

  const reorder = ({ oldIndex, newIndex }) => {
    const sortedValue = arrayMove(value, oldIndex, newIndex)
    sortedValue.map((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.position = (index + 1) * 10
      return item
    })
    setValue([...sortedValue])
  }

  const input = (
    <div className="p-2 bg-black rounded md:py-4 md:px-2">
      <div className="flex items-center justify-between mt-1 text-white">
        <span>{label} {parentIsSorting ? '(Exit sorting mode to edit)' : ''}</span>
      </div>
      {!parentIsSorting && <>
        <div
          className={`${
            !innerForm ? 'sticky top-0 z-30' : ''
          }  flex flex-wrap pt-2 overflow-scroll text-xs bg-black lg:overflow-hidden`}
        >
          <div
            onClick={() => setSorting(!sorting)}
            className={`p-1 mb-2 mr-2 bg-white rounded cursor-pointer uppercase ${
              sorting ? 'bg-orange' : ''
            }`}
          >
            {sorting ? 'Exit sorting mode' : 'Sort'}
          </div>
          {!sorting
          && Object.keys(modelsAvailable).map(modelKey => (
            <span
              key={modelKey}
              onClick={() => addModel(modelKey)}
              className="relative flex items-center p-1 pr-6 mb-2 mr-2 text-black bg-white rounded cursor-pointer hover:wght-semibold"
            >
              <span>{modelsAvailable[modelKey]}</span>
              <span
                style={{ backgroundColor: colorFromString(modelKey, 0.3) }}
                className="absolute top-0 bottom-0 right-0 w-4"
              ></span>
            </span>
          ))}
        </div>
        <List
          items={value}
          sorting={sorting}
          models={models}
          languages={languages}
          files={files}
          updateStored={updateStored}
          deleteModel={deleteModel}
          showErrors={showErrors}
          modelsAvailable={modelsAvailable}
          shouldCancelStart={() => !sorting}
          onSortEnd={reorder}
        />
      </>
      }
    </div>
  )

  return translatable ? (
    <LanguageSelector
      language={language}
      onChangeLanguage={v => setLanguage(v)}
    >
      {input}
    </LanguageSelector>
  ) : (
    input
  )
}

export default Models
