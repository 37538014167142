import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useHistory,
} from 'react-router-dom'
import config from '../config'
import Edit from './Edit'
import List from './List'
import Container from './Container'
import Cookies from 'js-cookie'
import Images from './Images'

const Main = () => {

  const [data, setData] = useState(false)

  const deploy = () => {
    if (window.confirm('##Deploy##\n\nYou should only deploy once everything is correct.')) {
      fetch(config.deploy.url, {
        method: 'POST',
        body: {},
      })
      .then(response => console.log('Deployed'))
      alert('It might take a while to publish the changes...')
      window.open(config.deploy.afterDeployUrl)
    }
  }

  const history = useHistory()
  const logout = () => {
    Cookies.remove('token')
    Cookies.remove('user')
    history.replace('/login')
  }
  let username = 'Anonymous'
  if (Cookies.get('user')) {
    const userData = JSON.parse(Cookies.get('user'))
    if (userData) {
      username = userData.name
    }
  }

  const isMenuItemActive = (item) => {
    let active = false
    if (typeof item.items !== 'undefined') {
      item.items.forEach(subitem => {
        if (!active && history.location.pathname.includes(subitem.type)) {
          active = true
        }
      })
    }
    if (!active) {
      return history.location.pathname.includes(item.type)
    }
    return active
  }

  useEffect(() => {
    fetch(`${config.apiURL}api/deploy`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
      },
    })
    .then(response => response.json().then(data => ({ status: response.status, body: data })))
    .then(response => {
      if (response.status === 200) {
        setData(response.body)
      } else {
        console.log('Error', response.status, response.body.error)
        if (response.status === 403) {
          Cookies.remove('token')
          history.push('/login')
        }
      }
    })
  }, [])

  return (
    <div className="flex flex-col min-h-screen">
      <nav className="py-12 bg-white">
        <Container className="justify-between md:flex">
          <div>
            <ul className="flex">
              {config.menu.map(item => (
                <li key={item.type} className="mr-4">
                  <NavLink className="hover:wght-semibold" activeClassName="wght-semibold" to={item.path || `/admin/list/${item.type}`}>
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
            {config.menu.map(item => {
              if (isMenuItemActive(item)) {
                return (
                  <ul key={item.type} className="flex mt-4 text-xs">
                    {
                      item.items && item.items.map(subitem => (
                        <li key={subitem.type} className="mr-4">
                          <NavLink className="hover:wght-semibold" activeClassName="wght-semibold" to={subitem.path || `/admin/list/${subitem.type}`}>
                            {subitem.title}
                          </NavLink>
                        </li>
                      ))
                    }
                  </ul>
                )
              }
              return null
            })}
          </div>
          <div className="hidden wght-semibold md:flex">
            <div className="mr-2">
              <a href="/admin/images" className="p-2 text-xs text-center rounded cursor-pointer bg-orange block">Images</a>
            </div>
            {config.deploy &&
            <div>
              <div className="block p-2 text-xs text-center rounded cursor-pointer bg-orange" onClick={deploy}>Deploy {config.deploy.title}</div>
              {data && data.deploy && <span className="text-xxs wght-normal">Last deploy <strong className="wght-semibold">{data.deploy.status}</strong> at {data.deploy.creationDate}</span>}
            </div>}
          </div>
        </Container>
      </nav>
      <div className="flex-1 py-12">
        <Container>
          <Switch>
            <Route path="/admin/list/:name/:id?">
              <List />
            </Route>
            <Route path="/admin/edit/:name/:id?">
              <Edit />
            </Route>
            <Route path="/admin/images">
              <Images />
            </Route>
          </Switch>
        </Container>
      </div>
      <div className="py-12 bg-white">
        <Container>
          <div className="flex justify-between">
            <div className="text-xs">
              👋 Hi <span className="capitalize">{username}</span>. Would you like to <span onClick={logout} className="cursor-pointer wght-bold hover:wght-semibold">log out</span>?
            </div>
            <div className="text-xs">
              {config.title}
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default Main
