import React, { useState } from 'react'
import config from '../config'
import Text from './Form/Text'
import Password from './Form/Password'
import Container from './Container'
import flatten from './Lib/flatten'
import Cookies from 'js-cookie'
import sha1 from 'js-sha1'
import {
  useHistory,
} from 'react-router-dom'

const Login = () => {
  const history = useHistory()
  const [data, setData] = useState({})
  const [errorMessage, setErrorMessage] = useState(false)
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [loading, setLoading] = useState(false)

  const updateData = (key, value, errs = []) => {
    data[key] = value
    setData({...data})
    errors[key] = errs
    setErrors({...errors})
  }

  const login = () => {
    setErrorMessage(false)
    const errs = flatten(errors)
    if (Object.keys(errs).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setLoading(true)
      fetch(`${config.apiURL}session/login/${data.username}/${sha1(data.password)}`, {
        method: 'GET',
      })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          Cookies.set('user', JSON.stringify(response.body.user))
          Cookies.set('token', response.body.token)
          history.replace(`/admin/list/${config.menu[0].type}`)
        } else {
          setErrorMessage(response.body.error)
        }
      })
    }
  }

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="md:w-1/2 lg:w-1/4">
        <Container>
          <h2 className="mb-8 text-xl wght-semibold">Login</h2>
          <div className="mb-8">
            <div className="mb-4">
              <Text
                storedData={{}}
                showErrors={showErrors}
                onChange={updateData}
                name="username"
                translatable={false}
                options={{ label: 'Username' }}
                validations={{ not_blank: 'Not blank' }}
              />
            </div>
            <Password
              storedData={{}}
              showErrors={showErrors}
              onChange={updateData}
              name="password"
              translatable={false}
              options={{ label: 'Password' }}
              validations={{ not_blank: 'Not blank' }}
              hideRepeat={true}
              onKeyPress={e => (e.key === 'Enter' && login())}
            />
            {errorMessage && <span className="block mt-4 text-error">{errorMessage}</span>}
          </div>
          <div className="flex items-end mb-8">
            {loading
            ? <span className="wght-bold">Loading...</span>
            : <span onClick={login} className="cursor-pointer wght-bold hover:wght-semibold">Login &#8599;</span>
            }
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Login