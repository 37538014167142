import Cookies from 'js-cookie'
import config from '../../config'
import generateToken from '../Lib/generateToken'

const updateMeta = (fileInfo, callback, errorCallback) => {
  const body = new FormData() 
  body.append('fileInfo', JSON.stringify(fileInfo))

  fetch(`${config.apiURL}media/upload`, {
    method: 'post',
    body,
    headers: {
      Authorization: `Bearer ${Cookies.get('token')}`,
    },
  }).then(res => res.json())
  .then(res => {
    if (res.id) {
      callback(res.id, res.fileInfo)
    } else {
      errorCallback()
    }
  })
}

const upload = (config, file, callback = () => {}, errorCallback = () => {}) => {

  const extension = file.name.split('.').pop()
  if (!extension) {
    errorCallback()
    return false
  }
  
  if (file.size / 1024 / 1024 > 10) {
    alert('Max file size 10MB')
    errorCallback()
    return false
  }

  const fileName = `${generateToken(60)}.${extension}`

  fetch(`${config.mediaURL}request-upload`, {
    method: 'put',
    body: JSON.stringify({
      appName: config.mediaName,
      fileName,
      fileType: file.type,
    }),
  })
  .then(response => response.json())
  .then(({ data }) => {
    fetch(data.url, {
      method: 'put',
      body: file,
    }).then((d2) => {

      const fileInfo = {
        name: fileName,
        type: file.type,
        size: file.size,
        originalName: file.name,
        extension,
        url: d2.url,
        resizable: data.resizable,
        quality: data.quality,
        sizes: data.sizes.join(','),
      }

      if (['image/jpeg', 'image/png'].includes(fileInfo.type)) {
        const img = new Image() 
        img.src = window.URL.createObjectURL(file)
        img.onload = () => {
          fileInfo.width = img.width
          fileInfo.height = img.height  
          updateMeta(fileInfo, callback, errorCallback)
        }
      } else {
        updateMeta(fileInfo, callback, errorCallback)
      }
      
    })
  })
}

export default upload