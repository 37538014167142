const config = {
  title: "Dallonses",
  // apiURL: 'http://localhost:9090/',
  apiURL: "https://api.dallonses.com/public/",
  mediaURL: "https://4n7dissrid.execute-api.eu-west-3.amazonaws.com/",
  mediaPreviewURL: "https://media.dallonses.com/_resized/",
  mediaName: "dallonses",
  media: {
    url: "https://media.dallonses.com/",
    public: "https://media.dallonses.com/",
    resized: "_resized/",
    name: "dallonses",
  },
  deploy: {
    title: "DEPLOY",
    url: "https://api.netlify.com/build_hooks/649192ef52adc1008909a57b",
    afterDeployUrl: "https://www.dallonses.com/",
  },
  menu: [
    {
      type: "Project",
      title: "Projects",
      items: [
        {
          type: "Project",
          title: "Projects",
        },
        {
          type: "Technology",
          title: "Technology",
        },
      ],
    },
    {
      type: "Home",
      title: "Pages",
      path: "/admin/edit/Home/1",
      items: [
        {
          type: "Home",
          title: "Home",
          path: "/admin/edit/Home/1",
        },
        {
          type: "Works",
          title: "Works",
          path: "/admin/edit/Works/1",
        },
        {
          type: "About",
          title: "About",
          path: "/admin/edit/About/1",
        },
        {
          type: "TechnologyPage",
          title: "Technology",
          path: "/admin/edit/TechnologyPage/1",
        },
        {
          type: "Services",
          title: "Services",
          path: "/admin/edit/Services/1",
        },
        {
          type: "Contact",
          title: "Contact",
          path: "/admin/edit/Contact/1",
        },
        {
          type: "Careers",
          title: "Careers",
          path: "/admin/edit/Careers/1",
        },
        {
          type: "Page",
          title: "Pages",
        },
        {
          type: "Common",
          title: "Common",
          path: "/admin/edit/Common/1",
        },
      ],
    },
    {
      type: "Service",
      title: "Services",
      items: [
        {
          type: "Service",
          title: "Services",
        },
        {
          type: "ServiceCategory",
          title: "Categories",
        },
      ],
    },
    {
      type: "Client",
      title: "Others",
      items: [
        {
          type: "Client",
          title: "Clients",
        },
        {
          type: "JobOffer",
          title: "Job Offers",
        },
      ],
    },
    {
      type: "User",
      title: "Config",
      items: [
        {
          type: "User",
          title: "Users",
        },
        {
          type: "Language",
          title: "Languages",
        },
        {
          type: "Literal",
          title: "Literals",
        },
      ],
    },
  ],
};

export default config;
