import React, { useState, useEffect } from 'react'
import {
  useHistory,
  Link,
} from 'react-router-dom'
import config from '../config'
import Cookies from 'js-cookie'

const Translation = ({
  modelName
}) => {

  const history = useHistory()
  const [languages, setLanguages] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [showTranslations, setShowTranslations] = useState(false)
  const [uploadingTranslate, setUploadingTranslate] = useState(false)
  const [downloadingTranslate, setDownloadingTranslate] = useState(false)

  useEffect(() => {
    fetch(`${config.apiURL}models/translations/languages`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
      },
    })
    .then(response => response.json().then(data => ({ status: response.status, body: data })))
    .then(response => {
      if (response.status === 200) {
        setLanguages(response.body.languages)
        setSelectedLanguage(response.body.languages[0])
      } else {
        console.log('Error', response.status, response.body.error)
        if (response.status === 403) {
          Cookies.remove('token')
          history.push('/login')
        }
      }
    })
  }, [])

  const importTranslations = () => {
    const input = document.querySelector('input#import')
    if (input.files.length === 0 || !selectedLanguage) {
      alert('You must select a file')
    } else {
      if (window.confirm(`Are you sure you want to upload and replace the contents for the  **${input.files.length}** files? Make sure their contents are in the right format.`)) {
        setUploadingTranslate(true)
        const payload = new FormData();
        let i = 0
        for (let i = 0, f; f = input.files[i]; i++) {
          payload.append(`file${i}`, f);
        }
        fetch(`${config.apiURL}models/translations/import/${modelName}/${selectedLanguage}`,
        {
          body: payload,
          method: 'post',
          headers: {
            'Authorization': `Bearer ${Cookies.get('token')}`,
          }
        })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          setUploadingTranslate(false)
          if (response.status === 200) {
            alert(response.body.processed.join('\n'))
            window.location.reload()
          } else {
            alert('There was an error...')
          }
        })
      }
    }
  }

  const exportTranslations = () => {
    window.open(`${config.apiURL}models/translations/export/${modelName}`)
    // fetch(`${config.apiURL}models/translations/export/${modelName}`,
    // {
    //   method: 'GET',
    //   headers: {
    //     'Authorization': `Bearer ${Cookies.get('token')}`,
    //     'Content-Type': 'text/plain'
    //   }
    // })
    // .then(response => response.blob())
    // .then(response => {
    //   let objectUrl = window.URL.createObjectURL(response);
    //   let anchor = document.createElement("a");
    //   document.body.appendChild(anchor);
    //   anchor.href = objectUrl;
    //   anchor.download = ``
    //   anchor.click();
    //   window.URL.revokeObjectURL(objectUrl);
    // })
  }

  return (
    <div className="p-4 mb-4 bg-white">
        <div className="cursor-pointer hover:wght-semibold" onClick={() => setShowTranslations(!showTranslations)}>{showTranslations ? 'Hide translations' : 'Show translations'}</div>
        <div className={`${showTranslations ? 'flex mt-4' : 'hidden'}`}>
          <div className="mr-8 bg-white rounded">
            {downloadingTranslate ?
            <span className="text-sm cursor-pointer hover:wght-semibold">Exporting translation...</span>
            : <span onClick={exportTranslations} className="inline-block p-2 text-xs rounded cursor-pointer bg-orange hover:wght-semibold">Export translations</span>}
          </div>
          <div className="rounded g-white ">
            <div className="flex">
              <div className="mr-4">
                <select className="border rounded" onChange={e => setSelectedLanguage(e.target.value)}>
                  {languages.map(language => <option key={language} value={language}>{language}</option>)}
                </select>
              </div>
              <div>
                <input multiple className="bg-white appearance-none text-xxs" id="import" type="file"/>
              </div>
            </div>
            {uploadingTranslate ?
            <span className="text-xs cursor-pointer hover:wght-semibold">Uploading translation...</span>
            : <span onClick={importTranslations} className="inline-block p-2 my-2 text-xs rounded cursor-pointer bg-purple hover:wght-semibold">Import translations</span>}
            <span className="block text-xxs">Select the language to import. You can upload one file. The format of the files MUST be the same as you downloaded it.</span>
          </div>
        </div>
      </div>
  )
}

export default Translation