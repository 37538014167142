import React, { useState, useEffect } from 'react'
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom'
import config from '../config'
import Translation from './Translation'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'

const numberOfItems = 1000

const List = () => {
  const history = useHistory()
  const { name: modelName, id } = useParams()
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(modelName)
  const [page, setPage] = useState(0)
  const [duplicatedId, setDuplicatedId] = useState(false)
  const [hasMorePages, setHasMorePages] = useState(false)

  useEffect(() => {
    setData(false)

    // TODO: refactor
    let title = modelName
    config.menu.forEach(item => {
      if (item.type === modelName && title === modelName) {
        title = item.title
      } else if (item.items) {
        item.items.forEach(item2 => {
          if (item2.type === modelName && title === modelName) {
            title = item2.title
          }
        })
      }
    })
    setTitle(`List ${title}`)

    fetch(`${config.apiURL}models/list/${modelName}/${numberOfItems}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
      },
    })
    .then(response => response.json().then(data => ({ status: response.status, body: data })))
    .then(response => {
      if (response.status === 200) {
        setData(response.body)
        setHasMorePages(response.body.length === numberOfItems)
      } else {
        console.log('Error', response.status, response.body.error)
        if (response.status === 403) {
          Cookies.remove('token')
          history.push('/login')
        }
      }
    })
  }, [modelName])

  const duplicate = (id) => {
    if (window.confirm('Duplicate?')) {
      fetch(`${config.apiURL}models/duplicate/${modelName}/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
      })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setDuplicatedId(response.body.id)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          }
        }
      })
    }
  }

  const remove = (id) => {
    if (window.confirm('Delete?')) {
      fetch(`${config.apiURL}models/delete/${modelName}/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
      })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          data.items = data.items.filter(item => item.id !== id)
          setData({...data})
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          }
        }
      })
    }
  }

  return (
    <div>
      <Helmet>
        <title>{title} – {config.title}</title>
      </Helmet>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl wght-semibold">{title}</h2>
        <Link to={`/admin/edit/${modelName}`} className="cursor-pointer hover:wght-semibold">Add</Link>
      </div>
      {!data && <div>Loading...</div>}
      {data && data.items.length === 0 && <div>Nothing to list</div>}
      {data && data.items.length > 0 && <Translation modelName={modelName} />}
      {data && data.items.length > 0 && <table className="w-full text-left table-auto">
        <thead className="w-full bg-white">
          <tr>
            {Object.values(data.config.list).map(label => (
              <th key={label} className="px-4 py-2 wght-semibold">{label}</th>
            ))}
            <th className="px-4 py-2 text-right wght-semibold">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((item, index) => <tr key={item.id} className={`${index % 2 ? 'bg-grayLighter' : ''}`}>
            {Object.keys(data.config.list).map(key => (
              <td key={key} className="px-4 py-2">{typeof item[key] !== 'undefined' && item[key]}</td>
            ))}
            <td className="px-4 py-2 text-right">
              <Link className="mr-4 cursor-pointer hover:wght-semibold" to={`/admin/edit/${modelName}/${item.id}`}>Edit</Link>
              <span className="mr-4 cursor-pointer hover:wght-semibold" onClick={() => duplicate(item.id)}>Duplicate</span>
              <span className="cursor-pointer hover:wght-semibold" onClick={() => remove(item.id)}>Delete</span>
            </td>
          </tr>)}
        </tbody>
      </table>}
      {hasMorePages &&
      <div className="flex mt-8">
        {page > 0 && <span onClick={() => setPage(page - 1)} className="mr-4 cursor-pointer hover:wght-semibold">Previous page</span>}
        <span onClick={() => setPage(page + 1)} className="mr-4 cursor-pointer hover:wght-semibold">Next page</span>
      </div>}
    </div>
  )
}

export default List